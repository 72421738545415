




























































import { Component, Vue, Watch } from 'vue-property-decorator';
import EventBus from '@/common/EventBus';
import ProductCardDetails from '@/components/products/ProductCardDetails.vue';
import LoadingModal from '@/common/LoadingModal.vue';
import _ from 'lodash';
import {State, Action} from 'vuex-class';
import Livechat8x8 from '@/components/layouts/Livechat8x8.vue';

@Component({
  name: 'App',
  components: {
    LoadingModal,
    ProductCardDetails,
    Livechat8x8
  }
})
export default class App extends Vue {
  @State private app: any;
  @State private cms: any;
  @Action('app/setLoadingText') private setLoadingText: any;

  private configReady = false;
  private themeReady = false;

  private items = {
    root: ['html'],
    app: ['#app'],
    button: ['.custom-button:not(.custom-chip)[type="button"]'],
    chip: ['.custom-chip[type="button"]'],
    productCard: ['.product', '.v-card:not(.v-sheet--tile):not(.v-card--shaped)', '.v-card'],
    configSummary: ['.summary'],
    section: [
      '.product-wrapper .v-expansion-panel',
      'div.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active',
      'div.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)',
      'div.add-coverage.v-expansion-panels div.v-expansion-panel',
      '.coverage-details .v-expansion-panel',
      '.page.summ .card-container',
      '.page .payment-card',
      '.v-card:not(.v-sheet--tile):not(.v-card--shaped).summary-card'
    ],
    dialog: ['.v-dialog'],
    field: ['.v-input:not(.v-input--radio-group):not(.v-input--switch):not(.v-input--checkbox) .v-input__slot']
  };

  @Watch('app.config')
  private onConfigReady(newVal, oldVal) {
    if (newVal && !this.configReady) {
      this.configReady = true;
    }
  }

  @Watch('cms.theme')
  private onThemeReady(newVal, oldVal) {
    if (newVal && !this.themeReady) {
      this.themeReady = true;
    }
  }

  get isError() {
    return _.get(this.$route, 'name', '') === 'error';
  }

  get scrollBarOffset() {
    return _.get(this.cms, 'theme.cssObject.section.border-radius', 0);
  }

  private mounted() {
    EventBus.$emit('open-loading-dialog', this.$t(`notifications.init.default`));
    document.onkeydown = (e) => {
      if (e.key === 'Backspace' && _.get(e, 'target.parentElement.className', '') === 'v-select__selections') {
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }
      }
    };
    setTimeout(() => {
      EventBus.$emit('close-loading-dialog');
    }, 300);
  }

  private getCSS(parent, cmsType) {
    let css = '';
    const classesIds = {};
    const cssJSON = _.get(this.cms, 'theme.cssObject.' + cmsType, {});
    _.forEach(parent, (p) => {
      css += `${p}{`;
      _.forEach(cssJSON, (value, prop) => {
        if (typeof value === 'object') {
          classesIds[prop] = value;
        } else {
          css += `${prop}:${value};`;
        }
      });
      css += '}';
    });
    _.forEach(parent, (p) => {
      _.forEach(classesIds, (content, selector) => {
        css += ` ${p} ${selector}{`;
        _.forEach(content, (value, prop) => {
          css += `${prop}:${value};`;
        });
        css += '}';
      });
    });
    return css;
  }

  private getCSSforKey(key) {
    const selectors = _.get(this.cms, 'theme.cssObject.selectors', {})[key];
    let css = '';
    for (const selector of selectors) {
      css += selector + ' {\n';
      const definitions = _.get(this.cms, 'theme.cssObject.definitions', {})[key];
      for (const definition of Object.keys(definitions)) {
        const isClass = definition.match(/^\./);
        const body = isClass ? this.getClassBody(definitions[definition]) : definitions[definition];
        const separatorBefore = isClass ? '{' : ':';
        const separatorAfter = isClass ? '}' : ';';
        css += definition + separatorBefore + body + separatorAfter + '\n';
      }
      css += '}\n';
    }
    return css;
  }

  private getClassBody(definition) {
    let body = '';
    for (const property of Object.keys(definition)) {
      body += property + ':' + definition[property] + ';\n';
    }
    return body;
  }

  get selectorKeys() {
    const all = _.get(this.cms, 'theme.cssObject.selectors', []);
    return _.keys(all).filter((k) => _.get(this.cms, 'theme.cssObject.definitions', {})[k]);
  }
}
